.RatingsCard {
  width: calc(100vw/5);
  height: calc(100vw/5*9/16);  
  perspective: 10000px;
  cursor: pointer;

  /* Filter over box-shadow to support the flip effect 
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16))
    drop-shadow(0 3px 6px rgba(0, 0, 0, 0.1));
  /* Apply transition to the filter */
  transition: filter 0.5s;
}

.RatingsCard__back:hover {
  /* Filter over box-shadow to support the flip effect
  filter: drop-shadow(0 14px 28px rgba(0, 0, 0, 0.25))
    drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1)); */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Green glow */
}

.RatingsCard__front,
.RatingsCard__back {
  background-color: rgb(255, 255, 255);
  border-radius: 1.1vw;
  height: 100%;
  position: absolute;
  width: 100%;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.6);
}

.RatingsCard__front {
  background-position: center;
  background-size: cover;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.RatingsCard__back {
  background-size: calc(100vw/5);
  align-items: center;
  display: flex;
  justify-content: center;

}

