.container {
    cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,  auto;
  }
  
.deck {
    position: absolute;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.deck:hover {
  z-index:1000;
}

.deck > div {
    touch-action: none;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    will-change: transform;
    border-radius: 20px;
    /* offset-x | offset-y | blur-radius | spread-radius | color; Any number of shadows, separated by commas 
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.6);*/
  }
  