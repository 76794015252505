:root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

.image-picker{
  color: var(--secondary);
}

.image-picker .title h1{
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.image-picker .title h2, .title p{
  text-align: center;
}
.image-picker .title h2{
  margin-top: 0px;
  font-size: 2.6rem;
}

/* upload form styles */
.image-picker form{
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 0px;
}
.image-picker label input{
  height: 0;
  width: 0;
  opacity: 0;
}
.image-picker label{
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
.image-picker label:hover{
  background: var(--primary);
  color: white;
}
.image-picker .output{
  height: 60px;
  font-size: 0.8rem;
}
.image-picker .error{
  color: var(--error);
}

/* progress bar styles  var(--primary) */
.image-picker .progress-bar{
  height: 15px;
  background: black;
  margin-top: 10px;
}

/* image grid styles */
.image-picker .img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.image-picker .img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.5;
}
.image-picker .img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.image-picker .backdrop-itu{
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.image-picker .backdrop-itu img{
  z-index: 10001;
  display: block;
  max-width: 60vw;
  max-height: 60vh;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}
